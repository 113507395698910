const $ = jQuery;

export default class Billing {
    constructor(options = null) {}

    init() {
        this.__listen();
        this.__addressChange();
    }

    __listen() {
        $("#shipping_email").focusout(function () {
            $("#billing_email").val($("#shipping_email").val());
        });

        $("#shipping_phone").focusout(function () {
            $("#billing_phone").val($("#shipping_phone").val());
        });

        $(
            ".woocommerce form.woocommerce-checkout .shipping_address :input"
        ).each(function () {
            var shippingFieldID = $(this).attr("id");

            $("#" + shippingFieldID).focusout(function () {
                var BillingFieldID = $(this)
                    .attr("id")
                    .replace("shipping", "billing");
                if ($("#bill-to-different-address-checkbox").is(":checked")) {
                    if (
                        shippingFieldID == "shipping_state" ||
                        shippingFieldID == "shipping_country"
                    ) {
                        $("#" + BillingFieldID)
                            .val($("#" + BillingFieldID).val())
                            .change();
                    } else {
                        $("#" + BillingFieldID).val(
                            $("#" + BillingFieldID).val()
                        );
                    }
                } else {
                    if (shippingFieldID == "shipping_state") {
                        $("#" + BillingFieldID)
                            .val($(this).val())
                            .change();
                    } else if (shippingFieldID == "shipping_country") {
                        var billVal = $("#" + BillingFieldID).val();
                        var shipVal = $("#" + shippingFieldID).val();
                        if (shipVal != billVal) {
                            $("#" + BillingFieldID)
                                .val($(this).val())
                                .change();
                        }
                    } else {
                        $("#" + BillingFieldID).val($(this).val());
                    }
                }
            });
        });
    }

    __addressChange() {
        $("body").on("change", "#bill-to-different-address-checkbox", () => {
            if (this.checked) {
                billinhandshipping();
                $("#ship-to-different-address-checkbox").prop("checked", true);
                $(".woocommerce-billing-fields").slideDown();
                $("#billing_first_name").val("");
                $("#billing_last_name").val("");
                $("#billing_address_1").val("");
                $("#billing_address_2").val("");
                $("#billing_city").val("");
                $("#billing_state").val("").change();
                $("#billing_postcode").val("");
                $("#billing_country").val("").change();
                $("#billing_company").val("");
                // $("#billing_email").val('');
            } else {
                billinhandshipping();
                $("#ship-to-different-address-checkbox").prop("checked", false);
                $(".woocommerce-billing-fields").slideUp();
                $("#billing_first_name").val($("#shipping_first_name").val());
                $("#billing_last_name").val($("#shipping_last_name").val());
                $("#billing_address_1").val($("#shipping_address_1").val());
                $("#billing_address_2").val($("#shipping_address_2").val());
                $("#billing_city").val($("#shipping_city").val());
                $("#billing_state").val($("#shipping_state").val()).change();
                $("#billing_postcode").val($("#shipping_postcode").val());
                $("#billing_country")
                    .val($("#shipping_country").val())
                    .change();
                $("#billing_company").val($("#shipping_company").val());
                // $("#billing_email").val($("#shipping_email").val());
            }
        });
    }
}
