const $ = jQuery;

export default class AddToCart {
    constructor(options = null) {}

    init() {
        this.__ajaxCall();
    }

    __ajaxCall(options = null) {
        var buynow = false;

        // get grand parent function
        $.fn.gparent = function (recursion) {
            if (recursion > 1)
                return $(this)
                    .parent()
                    .gparent(recursion - 1);
            return $(this).parent();
        };

        //woocommerce add to basket AJAX on product page
        $("body").on(
            "click",
            ".ajaxSubmission .single_add_to_cart_button",
            (event) => {
                event.preventDefault();
                buynow = false;
                const clickedElement = event.currentTarget;
                const form = $(clickedElement).closest("form");

                var product_qty = form.find("input[name=quantity]").val() || 1;
                var product_id = form.find("input[name=product_id]").val();
                var variation_id = form.find("input[name=variation_id]").val();

                var data = {
                    action: "woocommerce_ajax_add_to_cart",
                    product_id: product_id,
                    product_sku: "",
                    quantity: product_qty,
                    variation_id: variation_id,
                };

                var bool = true;

                //check if form is a variable product and that an ID is selected by choosing attribute options
                if (form.hasClass("variation")) {
                    if (variation_id == "0") {
                        bool = false;
                    }
                }

                if (bool == true) {
                    $.ajax({
                        type: "post",
                        url: wc_add_to_cart_params.ajax_url,
                        data: data,
                        beforeSend: function (response) {
                            $(".ajaxmessage").slideUp();
                            $(".successmessage").slideUp();
                            $(clickedElement)
                                .removeClass("added")
                                .addClass("loading");
                        },
                        complete: function (response) {
                            $(clickedElement)
                                .addClass("added")
                                .removeClass("loading");
                        },
                        success: function (response) {
                            if (response.error) {
                                $(".ajaxmessage").slideDown();
                                $(".ajaxmessage").html(response.notices);
                            } else {
                                if (
                                    $(clickedElement)
                                        .gparent(2)
                                        .hasClass("inmodal")
                                ) {
                                    //for within modal
                                    $(document.body).trigger("added_to_cart", [
                                        response.fragments,
                                        response.cart_hash,
                                        // $(clickedElement),
                                    ]);

                                    $(clickedElement).hide();
                                    var title = $(clickedElement)
                                        .gparent(2)
                                        .data("product");
                                    $(".successmessage span.title").html(title);
                                    $(clickedElement)
                                        .gparent(2)
                                        .append(
                                            '<p class="addedtocart position-relative"><span class="addedSuccess"></span>Added to Cart</p>'
                                        );
                                    $(".successmessage").slideDown();
                                } else {
                                    //for modal

                                    console.log("response", response);

                                    // return false;
                                    $(document.body).trigger("added_to_cart", [
                                        response.fragments,
                                        response.cart_hash,
                                        // $(clickedElement),
                                    ]);
                                    $("#updateCartModal").modal("show");
                                    $(".addedtocart").remove();
                                    $(
                                        ".inmodal .single_add_to_cart_button"
                                    ).show();
                                    var title = form.parent().data("product");
                                    $(".successmessage span.title").html(title);
                                    $(".successmessage").slideDown();
                                }

                                if (
                                    $(".basket-item-count .cart-items-count")
                                        .length
                                ) {
                                    var currentValue = $(
                                        ".basket-item-count .cart-items-count"
                                    ).html();
                                } else {
                                    currentValue = 0;
                                }

                                var addition = product_qty;

                                var total =
                                    parseInt(addition) + parseInt(currentValue);

                                var lastChild = $("button.cartButton")
                                    .find("svg")
                                    .nextAll(".basket-item-count")
                                    .last();
                                if (lastChild.length > 0) {
                                    lastChild.remove();
                                }

                                $("button.cartButton ")
                                    .find("svg")
                                    .after(
                                        '<div class="basket-item-count"><span class="cart-items-count text-white">' +
                                            total +
                                            "</span></div>"
                                    );
                            }
                        },
                    });
                }
            }
        );
    }
}
