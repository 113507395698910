import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const $ = jQuery;

export default class Swipers {
    constructor(options = null) {}

    init() {
        this.__swiper1();
        this.__featuredGamesSwiper();
        this.__reviewsSwiper();
        this.__conditionSwiper();
        this.__homeSwiper();
        this.__bundleSwiper();
    }

    __swiper1() {
        const $swiperBlocks = $(".swiper--programme");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            const swiper = new Swiper("#" + swiperBlockId, {
                speed: 1000,
                slidesPerView: 1,
                spaceBetween: 22.5,
                autoplay: false,
                loop: true,
                navigation: {
                    nextEl: ".swiper-next-" + swiperBlockId,
                    prevEl: ".swiper-prev-" + swiperBlockId,
                },
                pagination: {
                    el: ".swiper-pagination-" + swiperBlockId,
                    type: "bullets",
                    clickable: false,
                },
            });
        }
    }

    __featuredGamesSwiper() {
        const $swiperBlocks = $(".featuredGamesSwiper");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            const swiper = new Swiper("#" + swiperBlockId, {
                speed: 1000,
                slidesPerView: 5,
                spaceBetween: 55,
                watchOverflow: true,
                autoplay: false,
                loop: true,
                navigation: {
                    nextEl: ".swiper-next-" + swiperBlockId,
                    prevEl: ".swiper-prev-" + swiperBlockId,
                },
                breakpoints: {
                    1280: {
                        slidesPerView: 5,
                    },
                    991: {
                        slidesPerView: 4,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    480: {
                        slidesPerView: 2,
                    },
                    0: {
                        slidesPerView: 1,
                    },
                },
            });
        }
    }

    __reviewsSwiper() {
        const $swiperBlocks = $(".reviewsSwiper");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            const swiper = new Swiper("#" + swiperBlockId, {
                speed: 750,
                slidesPerView: 1,
                autoplay: false,
                loop: true,
                effect: "fade",
                fadeEffect: {
                    crossFade: true,
                },
                navigation: {
                    nextEl: ".swiper-next-" + swiperBlockId,
                    prevEl: ".swiper-prev-" + swiperBlockId,
                },
            });
        }
    }

    __conditionSwiper() {
        const $swiperBlocks = $(".conditionSwiper");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            const swiper = new Swiper("#" + swiperBlockId, {
                speed: 750,
                slidesPerView: 1,
                autoplay: false,
                loop: true,
                pagination: {
                    el: ".pagination-" + swiperBlockId,
                    type: "bullets",
                    clickable: false,
                },
                navigation: {
                    nextEl: ".swiper-next-" + swiperBlockId,
                    prevEl: ".swiper-prev-" + swiperBlockId,
                },
            });
        }
    }

    __homeSwiper() {
        const $swiperBlocks = $(".homeSwiper");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            const swiper = new Swiper("#" + swiperBlockId, {
                speed: 750,
                slidesPerView: 1,
                autoplay: false,
                loop: true,
                effect: "fade",
                fadeEffect: {
                    crossFade: true,
                },
                pagination: {
                    el: ".pagination-" + swiperBlockId,
                    type: "bullets",
                    clickable: false,
                },
                navigation: {
                    nextEl: ".swiper-next-" + swiperBlockId,
                    prevEl: ".swiper-prev-" + swiperBlockId,
                },
            });
        }
    }

    __bundleSwiper() {
        const $swiperBlocks = $(".bundleSwiper");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            const swiper = new Swiper("#" + swiperBlockId, {
                speed: 750,
                slidesPerView: 1,
                autoplay: false,
                loop: true,
                effect: "fade",
                fadeEffect: {
                    crossFade: true,
                },
                pagination: {
                    el: ".pagination-" + swiperBlockId,
                    type: "bullets",
                    clickable: false,
                },
                navigation: {
                    nextEl: ".swiper-next-" + swiperBlockId,
                    prevEl: ".swiper-prev-" + swiperBlockId,
                },
            });
        }
    }
}
