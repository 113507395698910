const $ = jQuery;

export default class Coupon {
    constructor(options = null) {}

    init() {
        this.__coupon();
    }

    __coupon() {
        $("body").on("click", "#checkout_apply_coupon", (event) => {
            event.preventDefault();

            var code = $("#checkout_coupon_code").val();
            console.log(code);

            var button = event.currentTarget;

            $(button).val("Applying.");

            $.ajax({
                type: "POST",
                url: site_data.adminajax,
                data: {
                    action: "coupon",
                    coupon_code: code,
                },
            })
                .done(function (response) {
                    setTimeout(function () {
                        //reload with ajax
                        $(document.body).trigger("update_checkout");
                        $(button).val("Apply Coupon");
                    }, 2000);
                })
                .fail(function () {})
                .always(function () {});
        });
    }
}
