const $ = jQuery;

export default class PlusMinus {
    constructor(options = null) {}

    init() {
        this.__plusminus();
    }

    __plusminus() {
        $(document).on(
            "click",
            "button.cartplus, button.cartminus",
            (event) => {
                const clickedElement = event.currentTarget;

                var qty = $(clickedElement).parent(".quantity").find(".qty");
                var val = parseFloat(qty.val());
                var max = parseFloat(qty.attr("max"));
                var min = parseFloat(qty.attr("min"));
                var step = parseFloat(qty.attr("step"));

                var name = qty.attr("name");

                var regex = /cart\[(.*?)\]\[qty\]/;
                var match = name.match(regex);

                // The desired value (product_id is cart number)
                var product_id = match ? match[1] : null;

                if ($(clickedElement).is(".cartplus")) {
                    if (max && max <= val) {
                        qty.val(max).change();
                    } else {
                        qty.val(val + step).change();
                    }
                } else {
                    if (min && min >= val) {
                        qty.val(min).change();
                    } else if (val > 1) {
                        qty.val(val - step).change();
                    }
                }

                // update cart and aync number on basket icon
                $.ajax({
                    type: "POST",
                    url: site_data.adminajax,
                    data: {
                        action: "custom_update_cart_total",
                        product_id: product_id,
                        qty: qty.val(),
                    },
                    success: function (response) {
                        // Update the cart totals
                        $(".cartButton .cart-items-count").html(response);
                    },
                });

                $("[name='update_cart']").trigger("click");
            }
        );
    }
}
