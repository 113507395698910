// import js dependencies

import AjaxPost from "./ajax";
import Swipers from "./swiper";

// import woocommerce js dependencies
import AddToCart from "./woocommerce/addToCart";
import Billing from "./woocommerce/billing";
import WoocommerceSwipers from "./woocommerce/swiper_woocommerce";
import BasketCount from "./woocommerce/basket_count";
import Coupon from "./woocommerce/coupon";
import PlusMinus from "./woocommerce/plusMinus";
import Modal from "./modals";

jQuery(document).ready(($) => {
    //disable scroll script
    var lastWindowScrollTop = 0,
        userAgent = navigator.userAgent,
        $body = $("body"),
        isIOS = /iPhone|iPad/.test(userAgent),
        NO_SCROLL_CLASS = isIOS ? "ios-noscroll" : "non-ios-noscroll";

    function fixedBody() {
        if (isIOS) {
            lastWindowScrollTop = $(window).scrollTop();
            $body.addClass(NO_SCROLL_CLASS);
            $body.css("top", "-" + lastWindowScrollTop + "px");
        } else {
            $body.addClass(NO_SCROLL_CLASS);
        }
    }

    function looseBody() {
        $body.removeClass(NO_SCROLL_CLASS);
        if (isIOS) {
            $body.css("top", "");
            window.scrollTo(0, lastWindowScrollTop);
        }
    }

    $.fn.scrollableOverlay = function () {
        this.on("show", fixedBody);
        this.on("hide", looseBody);
    };

    $(".menu-primary-container").scrollableOverlay();

    if ($(".swiper-wrapper").length > 0) {
        const swipers = new Swipers({});
        swipers.init();
    }

    //lightbox Options
    lightbox.option({
        resizeDuration: 0,
        wrapAround: true,
        showImageNumberLabel: false,
        fitImagesInViewport: true,
        maxWidth: 800,
        maxHeight: 800,
    });

    //for woocommerce

    if ($("[data-tessellate='woocommerce-swiper']").length > 0) {
        const woocommerce_swipers = new WoocommerceSwipers({});
        woocommerce_swipers.init();
    }

    if ($(".product-remove").length > 0) {
        const update_cart_count = new AjaxPost({});
        update_cart_count.updateCart();
    }

    if ($(".ajaxSubmission .single_add_to_cart_button").length > 0) {
        const add_to_cart = new AddToCart({});
        add_to_cart.init();
    }

    // if ($(".woocommerce-shipping-fields").length > 0) {
    //     const billing = new Billing({});
    //     billing.init();
    // }

    if ($(".coupon").length > 0) {
        const coupon = new Coupon({});
        coupon.init();
    }

    if ($(".cartplus").length > 0) {
        const plusminus = new PlusMinus({});
        plusminus.init();
    }

    // when page loads
    const basketcount = new BasketCount({});
    basketcount.init();

    const modals = new Modal({});
    modals.init();
});
