import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const $ = jQuery;

export default class WoocommerceSwipers {
    constructor(options = null) {}

    init() {
        this.__swiper_woocommerce1();
    }

    __swiper_woocommerce1() {
        const $swiperBlocks = $(".gallery-main");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            const swiper = new Swiper("#" + swiperBlockId, {
                speed: 1000,
                slidesPerView: 1,
                spaceBetween: 22.5,
                autoplay: false,
                loop: true,
                pagination: {
                    el: ".swiper-pagination-" + swiperBlockId,
                    clickable: true,
                    renderBullet: function (index, className) {
                        var img = $("#gallery-main .swiper-slide")
                            .eq(index + 1)
                            .attr("data-bullet");
                        return (
                            '<span class="' +
                            className +
                            '"><img src="' +
                            img +
                            '" alt="" /></span>'
                        );
                    },
                },
            });
        }
    }
}
