const $ = jQuery;

export default class Modal {
    constructor(options = null) {}

    init() {
        this.__modals();
    }

    __modals() {
        jQuery(document).ready(function ($) {
            $(document).on("frmFormComplete", function (event, form, response) {
                var formKey = $(form).find('input[name="form_key"]').val();

                // Tried to do this via classes but it's too buggy. Need to work out how to toggle the bootstrap modal and hook into that functionality.
                // switch (formKey) {
                //     case "request-a-game":
                //         $("#requestModal").removeClass("show");
                //         $("#requestModal").css("display", "none");
                //         $("#requestConfirmationModal").css("display", "block");
                //         $("#requestConfirmationModal").addClass("show");

                //         $(".btn-close").click(function () {
                //             $("#requestConfirmationModal").removeClass("show");
                //             $("#requestConfirmationModal").css(
                //                 "display",
                //                 "none"
                //             );
                //             $(".modal-backdrop").removeClass("show");
                //             $(".modal-backdrop").css("display", "none");
                //         });
                //         $(".modal-backdrop").click(function () {
                //             $("#requestConfirmationModal").removeClass("show");
                //             $("#requestConfirmationModal").css(
                //                 "display",
                //                 "none"
                //             );
                //             $(".modal-backdrop").removeClass("show");
                //             $(".modal-backdrop").css("display", "none");
                //         });
                //         break;
                //     case "newsletter-signup":
                //         $("#newsletterModal").addClass("show");
                //         $("#newsletterModal").css("display", "block");
                //         $(".btn-close").click(function () {
                //             $("#newsletterModal").removeClass("show");
                //             $("#newsletterModal").css("display", "none");
                //             $(".modal-backdrop").removeClass("show");
                //             $(".modal-backdrop").css("display", "none");
                //         });
                //         $(".modal-backdrop").click(function () {
                //             $("#newsletterModal").removeClass("show");
                //             $("#newsletterModal").css("display", "none");
                //             $(".modal-backdrop").removeClass("show");
                //             $(".modal-backdrop").css("display", "none");
                //         });
                //         break;
                //     default:
                //         break;
                // }
            });
        });
    }
}
