const $ = jQuery;

export default class BasketCount {
    constructor(options = null) {}

    init() {
        this.__ajaxCall();
    }

    __ajaxCall(options = null) {
        $.ajax({
            type: "POST",
            url: site_data.adminajax,
            data: {
                action: "basket_icon_count",
            },
        })
            .done(function (response) {
                if (response != "null") {
                    $("button.cartButton ")
                        .find("svg")
                        .after(
                            '<div class="basket-item-count"><span class="cart-items-count text-white">' +
                                response +
                                "</span></div>"
                        );
                }
            })
            .fail(function () {})
            .always(function () {});
    }
}
