const $ = jQuery;

export default class AjaxPost {
    constructor(options = null) {}

    init() {
        this.__listen();
    }

    updateCart() {
        // Event listener for remove button click
        $("body").on("click", ".customRemove", (e) => {
            e.preventDefault();

            const clickedElement = e.currentTarget;

            var product_id = $(clickedElement).attr("data-product-id");

            $.ajax({
                type: "POST",
                url: site_data.adminajax,
                data: {
                    action: "custom_update_cart_total",
                    product_id: product_id,
                },
                success: function (response) {
                    // Update the cart totals
                    $(".cartButton .cart-items-count").html(response);
                },
            });
        });
    }

    __listen() {
        $("body").on("click", "[data-tds-ajax-click-action]", (event) => {
            event.preventDefault();
            this.tds_action = $(event.currentTarget).attr(
                "data-tds-ajax-click-action"
            );
            this.tds_data = $(event.currentTarget).attr("data-tds-data");

            this.__ajaxCall({
                $post_action: this.tds_action,
                $post_data: this.tds_data,
            });
        });

        $("body").on("change", "[data-tds-ajax-change]", (event) => {
            event.preventDefault();
            this.tds_action = $(event.currentTarget).attr(
                "data-tds-ajax-change-action"
            );
            this.tds_data = $(event.currentTarget).attr("data-tds-data");

            this.__ajaxCall({
                $post_action: this.tds_action,
                $post_data: this.tds_data,
            });
        });
    }

    __ajaxCall(options = null) {
        $.ajax({
            type: "POST",
            url: site_data.adminajax,
            data: {
                action: options.$post_action,
                data: options.$post_data,
            },
            dataType: "json",
            encode: true,
        })
            .success(function (response) {
                console.log("res", response);

                switch (options.$post_action) {
                    case "1":
                        $("#example").html(response);
                        break;
                    case "2":
                        $(".example").html(response);
                        break;
                    default:
                        break;
                }
            })
            .error(function (error) {
                console.log("error");
            });
    }
}
